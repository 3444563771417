var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { eventDetailSubject, marketMapping, sportsbookFilter, sportsbookList, sportsSummery } from "../../store/sportsbook.store";
import { dateTimeFormat, getMatchStatus, timeFormat, useForceUpdate } from "../../lib/misc.functions";
import _ from "lodash";
import { fillEventStatistics, generateCompetitionGroupList, mobileHighLightedMarket, myHighLightedMarketGroups, setFilterCompetitions, } from "../../services/sportsbook.services";
import { EventDetail, eventDetailVisiblity } from "./eventdetail";
import { getEvent } from "../../services/societyWon.services";
import { Eventlist } from "./widgets/eventlist";
import { highLightedMarketCategories } from "../../definitions/constants/market-categories.constant";
import { societywonEventIdMapping } from "../societywon/subpages/posts";
import { eventEndedStatuses } from "../../definitions/constants/event-result-type";
import { isMobileView } from "../../app";
import SideMenu from "../../shared/sidemenu/sidemenu";
import { useNavigate } from "react-router";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { modalServiceSubject } from "../../components/modal/modal";
import { Storage } from "../../lib/localstorege.service";
import { HighlightedMarkets } from "./components/highlightedmarkets";
var clearFilters = function () {
    sportsbookFilter.filterKeyword = '';
    sportsbookFilter.filterDays = [];
    sportsbookFilter.filterMBS = [];
    sportsbookFilter.filterCompetition = [];
    sportsbookFilter.filterLiveStream = false;
    sportsbookFilter.filterFavorite = false;
};
var selectedLiveComp = [];
var page = 0;
var SportsBook = function () {
    var _a;
    var navigate = useNavigate();
    var params = useParams();
    var forceUpdate = useForceUpdate();
    var width = window.innerWidth;
    var sportSlug = params.sportSlug == 'tumu' ? 'futbol' : params.sportSlug;
    var sportsCompetitions;
    var eventDetail = null;
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    var _b = useState(false), openLeftMenu = _b[0], setOpenLeftMenu = _b[1];
    var _c = useState(false), highLightedMarketModalIsOpened = _c[0], setHighLightedMarketModalIsOpened = _c[1];
    var _d = useState(null), selectedComp = _d[0], setSelectedComp = _d[1];
    var setHighLightedSelectedList = function (market, sportSlug) {
        var highlightedKey = "".concat(sportSlug).concat(params.eventType == 'canli' ? '-canli' : '');
        var indexOfMarket = myHighLightedMarketGroups[highlightedKey].indexOf(market);
        if (indexOfMarket !== -1) {
            myHighLightedMarketGroups[highlightedKey].splice(indexOfMarket, 1);
        }
        else {
            if (width > 1441) {
                if (myHighLightedMarketGroups[highlightedKey].length < 5) {
                    myHighLightedMarketGroups[highlightedKey].push(market);
                }
                else {
                    modalServiceSubject.next({
                        title: 'Uyarı',
                        content: 'En fazla 5 market seçebilirsiniz',
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0,
                    });
                }
            }
            else if (width > 968) {
                if (myHighLightedMarketGroups[highlightedKey].length < 4) {
                    myHighLightedMarketGroups[highlightedKey].push(market);
                }
                else {
                    modalServiceSubject.next({
                        title: 'Uyarı',
                        content: 'En fazla 4 market seçebilirsiniz',
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0,
                    });
                }
            }
            else {
                if (myHighLightedMarketGroups[highlightedKey].length < 2) {
                    myHighLightedMarketGroups[highlightedKey].push(market);
                }
                else {
                    modalServiceSubject.next({
                        title: 'Uyarı',
                        content: 'En fazla 2 market seçebilirsiniz',
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0,
                    });
                }
            }
        }
        Storage.set("highLightedMarketCategories", myHighLightedMarketGroups);
        forceUpdate();
    };
    // useEffect(() => {
    //     clearFilters();
    //     closeEventDetail();
    //     forceUpdate();
    //     console.log('forceUpdate 1');
    // }, [params.eventType, sportSlug, params.competitionId])
    useEffect(function () {
        if (width > 968) {
            var root = document.getElementById('root');
            root.classList.remove('is-home-page');
            root.classList.remove('betslip-Hidden');
            root.classList.add("page-full-size");
            root.classList.add("sportsbook");
            root.classList.add("betslip-Fixed");
            root.classList.add("compact-footer");
        }
        return function () {
        };
    }, []);
    useEffect(function () {
        // if (width > 968) {
        var root = document.getElementById('root');
        if (params.eventType === 'canli') {
            if (root.classList.contains('prematch')) {
                root.classList.remove('prematch');
                root.classList.add("live");
            }
            else {
                root.classList.add("live");
            }
        }
        else {
            if (root.classList.contains('live')) {
                root.classList.remove('live');
                root.classList.add("prematch");
            }
            else {
                root.classList.add("prematch");
            }
        }
        clearFilters();
        // }
    }, [params.eventType]);
    // if (width < 969 && betSlipVisiblity) {
    //     document.body.style.overflow = 'hidden';
    // } else {
    //     document.body.style.overflow = 'unset';
    // }
    useEffect(function () {
        var eventDetailSubject_subscription = eventDetailSubject.subscribe(function (res) {
            if (res && res === 'toggle') {
                eventDetailVisiblity.visible = false;
                closeEventDetail();
                forceUpdate();
            }
        });
        return function () {
            eventDetailSubject_subscription.unsubscribe();
        };
    }, []);
    var openEventDetail = function (event) {
        if (event) {
            eventDetailSubject.next(event);
        }
        if (isMobileView) {
            forceUpdate();
            window.scrollTo(0, 0);
        }
        else {
            var sportsBookContainer = document.getElementById('sportsbook-container-id');
            var multiColumnToIcon = document.getElementById('multi-column-to-icon-id');
            multiColumnToIcon.classList.add("bc-i-block-to-right");
            multiColumnToIcon.classList.remove("bc-i-block-to-left");
            sportsBookContainer.classList.remove("multi-column-view-expanded");
        }
    };
    var closeEventDetail = function () {
        eventDetailSubject.next(false);
        eventDetailVisiblity.visible = false;
        document.body.style.overflow = 'unset';
        if (isMobileView) {
            forceUpdate();
        }
    };
    var goToUrl = function (sportSlug, eventType) {
        navigate(base + "/sports/" + sportSlug + '/' + params.eventType);
        if (isMobileView) {
            betSlipVisibleSubject.next(false);
        }
    };
    var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
        if (sportSlug === el.sportSlug) {
            if ((params.eventType === 'tumu' && Number(el.eventType) === 1) ||
                (params.eventType === 'canli' && Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                (params.eventType === 'uzunvadeli' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                return el;
            }
        }
        else if (sportSlug == null && Number(el.eventType) !== 2) {
            return el;
        }
    });
    // console.log("originSportsbookList>>", originSportsbookList)
    var shownSportsBook = [];
    // fixme : performans iyileştirme lazım
    shownSportsBook = originSportsbookList.filter(function (el) {
        var filteredElement = el;
        if ((Number(filteredElement.isLiveEvent) === 0 || (Number(filteredElement.bettingPhase) === 0 && eventEndedStatuses.includes(filteredElement.liveScoreStatus))) &&
            marketMapping[String(filteredElement.eventId)].filter(function (m) { return Number(m.marketStatus) !== 0; }).length === 0) {
            el.isRemoved = true;
            filteredElement = null;
        }
        else {
            el.isRemoved = false;
        }
        if (filteredElement && sportsbookFilter.filterCompetition.length > 0) {
            if (sportsbookFilter.filterCompetition.indexOf(el.competitionId.toString()) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterDays.length > 0) {
            if (sportsbookFilter.filterDays.indexOf(el.eventDate.substring(0, 10)) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterTime >= 0) {
            try {
                var date = new Date(el.eventDate);
                var today = new Date();
                if (sportsbookFilter.filterTime == 0) {
                    if (date.getDate() == today.getDate()) {
                        filteredElement = el;
                    }
                    else {
                        filteredElement = null;
                    }
                }
                else {
                    var newDate = today.getTime() + sportsbookFilter.filterTime * 60000;
                    var eventDateTime = date.getTime();
                    if (eventDateTime <= newDate) {
                        filteredElement = el;
                    }
                    else {
                        filteredElement = null;
                    }
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        if (filteredElement && sportsbookFilter.filterLiveEvent) {
            if (Number(el.isLiveEvent) === 1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterFavorite) {
            if (el.isFavorite) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterKeyword.length > 1) {
            if (el.eventName.toLowerCase().indexOf(sportsbookFilter.filterKeyword.toLowerCase()) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        // console.log(filteredElement);
        if (filteredElement && params.eventType === 'canli') {
            if (Number(filteredElement.isLiveEvent) !== 1) {
                filteredElement = null;
            }
        }
        if (filteredElement) {
            if (params.eventType !== 'canli') {
                if (Number(filteredElement.bettingPhase) != 0) {
                    filteredElement = null;
                }
            }
        }
        // console.log(filteredElement);
        if (filteredElement) {
            return filteredElement;
        }
    });
    // console.log("shownSportsBook>>>", shownSportsBook);
    useEffect(function () {
        var _a;
        if (params.eventId) {
            var reloadEvent = sportsbookList.find(function (el) { return Number(el.eventId) === Number(params.eventId); });
            console.log(reloadEvent);
            if (reloadEvent) {
                openEventDetail(reloadEvent);
            }
            else {
                getEvent(Number(params.eventId)).then(function (event) {
                    event.isEnded = true;
                    societywonEventIdMapping[Number(event.eventId)] = event;
                    marketMapping[event.eventId] = event.markets;
                    fillEventStatistics([Number(params.eventId)]).finally(function () {
                        openEventDetail(societywonEventIdMapping[Number(params.eventId)]);
                    });
                });
            }
        }
        else {
            if (params.competitionId) {
                (_a = sportsbookFilter.filterCompetition).push.apply(_a, params.competitionId.split(','));
            }
            closeEventDetail();
        }
    }, [params.eventId, params.competitionId]);
    var marketGroup = myHighLightedMarketGroups["".concat(sportSlug).concat(params.eventType == 'canli' ? '-canli' : '')];
    useEffect(function () {
        if (isMobileView) {
            if (!sportsbookFilter.selectedCompetitionGroup.competitionGroupName) {
                try {
                    var sportsCompetitions_1 = generateCompetitionGroupList(originSportsbookList, [sportSlug]);
                    sportsbookFilter.selectedCompetitionGroup = sportsCompetitions_1.filter(function (el) {
                        return el.competitionGroupName == shownSportsBook[0].competitionGroupName;
                    });
                }
                catch (e) {
                }
            }
            if (!mobileHighLightedMarket.selected) {
                mobileHighLightedMarket.selected = highLightedMarketCategories[params.sportSlug || 'futbol'][0];
            }
        }
    }, []);
    var vh = window.outerHeight;
    return (_jsxs(_Fragment, { children: [!isMobileView && _jsxs("div", __assign({ id: "sportsbook-container-id", className: "sportsbook-container multi-column-view  multi-column-view-expanded  ".concat(openLeftMenu ? 'openLeftMenu' : '') }, { children: [_jsx(SideMenu, { competitionId: params.competitionId, sportSlug: params.sportSlug, openLeftMenu: openLeftMenu, eventType: params.eventType }, void 0), _jsx("div", __assign({ className: "sportsbook-center-section" }, { children: _jsxs("div", __assign({ className: "sportsbook-center-section-scroll" }, { children: [_jsx("div", __assign({ className: "prematch-page-bc", id: "sportsbook-center-section-scroll", onScroll: function () {
                                        var listenerElement = document.getElementById('sportsbook-center-section-scroll');
                                        // @ts-ignore
                                        var sct = listenerElement.scrollTop;
                                        if (sct > (vh / 4)) {
                                            if (page < Math.ceil(sct / vh)) {
                                                page = Math.ceil(sct / vh);
                                                forceUpdate();
                                            }
                                        }
                                    } }, { children: _jsxs("div", __assign({ className: "multi-column-resize-block", id: "multi-column-resize-block-scroll", onScroll: function () {
                                            var listenerElement = document.getElementById('multi-column-resize-block-scroll');
                                            // @ts-ignore
                                            var sct = listenerElement.scrollTop;
                                            if (sct > (vh / 4)) {
                                                if (page < Math.ceil(sct / vh)) {
                                                    page = Math.ceil(sct / vh);
                                                    forceUpdate();
                                                }
                                            }
                                        } }, { children: [_jsxs("div", __assign({ className: "comp-name-fav-row-bc" }, { children: [params.competitionId != null ?
                                                        _jsx(_Fragment, {}, void 0) :
                                                        _jsx("div", __assign({ className: "sp-sub-list-bc ".concat(params.sportSlug) }, { children: _jsx("i", { className: "sp-s-l-h-flag-bc bc-i-".concat(params.sportSlug) }, void 0) }), void 0), params.competitionId != null ? _jsx("p", __assign({ className: "comp-title-w-bc ellipsis3" }, { children: shownSportsBook && shownSportsBook.length > 0 && shownSportsBook[0].competitionGroupName }), void 0) : _jsx("p", __assign({ className: "comp-title-w-bc ellipsis" }, { children: shownSportsBook && shownSportsBook.length > 0 && shownSportsBook[0].sportName }), void 0), _jsx("div", __assign({ className: "settings-r-b-row-bc mr-4", onClick: function () { return setHighLightedMarketModalIsOpened(true); } }, { children: _jsx("div", __assign({ className: "form-control-bc select s-small has-icon filled", tabIndex: 0 }, { children: _jsxs("div", __assign({ className: "form-control-label-bc form-control-select-bc inputs " }, { children: [_jsx("i", { className: "select-icon-bc bc-i-settings" }, void 0), _jsx("span", __assign({ className: "ellipsis" }, { children: "Marketler" }), void 0), _jsx("i", { className: "form-control-icon-bc bc-i-small-arrow-down" }, void 0), _jsx("i", { className: "form-control-input-stroke-bc" }, void 0)] }), void 0) }), void 0) }), void 0), _jsx("span", __assign({ className: "multi-column-toggle-title" }, { children: "Favoriler" }), void 0), _jsx("i", { onClick: function () {
                                                            sportsbookFilter.filterFavorite = !sportsbookFilter.filterFavorite;
                                                            forceUpdate();
                                                        }, className: "ui-kit-toggle mr-4 ".concat(sportsbookFilter.filterFavorite ? 'active' : '') }, void 0), _jsx("i", { className: "comp-fav-icon-bc  bc-i-block-to-".concat(openLeftMenu ? 'left' : "right"), onClick: function () {
                                                            setOpenLeftMenu(function (prevState) {
                                                                var leftMenu = document.getElementById('left-menu-container');
                                                                var sportsBookContainer = document.getElementById('sportsbook-container-id');
                                                                var multiColumnToIcon = document.getElementById('multi-column-to-icon-id');
                                                                if (!prevState) {
                                                                    leftMenu.style.width = '300px';
                                                                    if (multiColumnToIcon.classList.value.includes("bc-i-block-to-right")) {
                                                                        multiColumnToIcon.classList.remove("bc-i-block-to-right");
                                                                        multiColumnToIcon.classList.add("bc-i-block-to-left");
                                                                        sportsBookContainer.classList.add("multi-column-view-expanded");
                                                                    }
                                                                }
                                                                else {
                                                                    leftMenu.style.width = '40px';
                                                                }
                                                                return !prevState;
                                                            });
                                                        } }, void 0)] }), void 0), _jsxs("div", __assign({ className: "multi-column-container" }, { children: [_jsx("i", { id: "multi-column-to-icon-id", className: "multi-column-to-icon bc-i-block-to-left", onClick: function () {
                                                            var sportsBookContainer = document.getElementById('sportsbook-container-id');
                                                            var multiColumnToIcon = document.getElementById('multi-column-to-icon-id');
                                                            if (multiColumnToIcon.classList.value.includes("bc-i-block-to-right")) {
                                                                multiColumnToIcon.classList.remove("bc-i-block-to-right");
                                                                multiColumnToIcon.classList.add("bc-i-block-to-left");
                                                                sportsBookContainer.classList.add("multi-column-view-expanded");
                                                            }
                                                            else {
                                                                multiColumnToIcon.classList.add("bc-i-block-to-right");
                                                                multiColumnToIcon.classList.remove("bc-i-block-to-left");
                                                                sportsBookContainer.classList.remove("multi-column-view-expanded");
                                                            }
                                                            forceUpdate();
                                                        } }, void 0), _jsxs("ul", __assign({ className: "multi-column-header" }, { children: [_jsx("li", {}, "multi-column-key--1"), marketGroup.map(function (el, key) {
                                                                return (_jsxs(React.Fragment, { children: [key != 0 && _jsx("li", { className: "multi-column-base" }, void 0), _jsxs("li", __assign({ className: "".concat(el.outcomeCount > 2 ? 'multi-column-WINNER' : 'multi-column-HANDICAP', " multi-column-count-").concat(el.outcomeCount) }, { children: [_jsx("p", { children: el.name }, void 0), _jsx("div", { children: el.labels.map(function (marketLabel, marketLabelKey) {
                                                                                        return _jsx("b", { children: marketLabel }, key + "-marketLabel-" + marketLabelKey);
                                                                                    }) }, void 0)] }), void 0)] }, "marketGroup-".concat(key)));
                                                            }), _jsx("li", {}, "multi-column-key--2")] }), void 0), _jsx(Eventlist, { page: page, openEventDetail: openEventDetail, sportsbookFilter: sportsbookFilter, params: params, shownSportsBook: shownSportsBook }, void 0)] }), void 0)] }), void 0) }), void 0), _jsx(EventDetail, { eventType: params.eventType, sportSlug: params.sportSlug, closeEventDetail: closeEventDetail, openEventDetail: openEventDetail }, void 0)] }), void 0) }), void 0)] }), void 0), isMobileView && _jsxs(_Fragment, { children: [params.competitionId == null && _jsxs("div", __assign({ className: "filter-sports-bc" }, { children: [_jsx("div", __assign({ className: "form-control-bc select s-small has-icon filled" }, { children: _jsxs("label", __assign({ className: "form-control-label-bc inputs" }, { children: [_jsx("i", { className: "select-icon-bc bc-i-time" }, void 0), _jsxs("select", __assign({ className: "form-control-select-bc ellipsis", onChange: function (e) {
                                                sportsbookFilter.filterTime = Number(e.target.value);
                                                forceUpdate();
                                            } }, { children: [_jsx("option", __assign({ value: "-1" }, { children: "T\u00DCM\u00DC" }), void 0), _jsx("option", __assign({ value: "0" }, { children: "Bug\u00FCn" }), void 0), _jsx("option", __assign({ value: "180" }, { children: "3 saat" }), void 0), _jsx("option", __assign({ value: "360" }, { children: "6 saat" }), void 0), _jsx("option", __assign({ value: "720" }, { children: "12 saat" }), void 0), _jsx("option", __assign({ value: "1440" }, { children: "24 saat" }), void 0), _jsx("option", __assign({ value: "2880" }, { children: "48 saat" }), void 0), _jsx("option", __assign({ value: "4320" }, { children: "72 saat" }), void 0)] }), void 0), _jsx("i", { className: "form-control-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0) }), void 0), _jsx(MobileSearchBar, {}, void 0)] }), void 0), !eventDetailVisiblity.visible && _jsxs("div", __assign({ className: "prematch-page-bc" }, { children: [params.competitionId == null &&
                                _jsx("div", __assign({ className: "horizontal-sl-list-container", "data-scroll-lock-scrollable": "" }, { children: _jsx("div", __assign({ className: "horizontal-sl-list pp-sport-type-holder-bc ext-2" }, { children: sportsSummery.map(function (item, key) {
                                            if (item.eventType != 2) {
                                                return _jsxs("div", __assign({ id: key, title: item.sportSlug, "data-badge": "", onClick: function () {
                                                        mobileHighLightedMarket.selected = null;
                                                        goToUrl(item.sportSlug, item.eventType);
                                                    }, className: "horizontal-sl-item-bc accordion-button ".concat(item.sportSlug, " ").concat(item.sportSlug == sportSlug ? 'active' : '', " ") }, { children: [_jsx("i", { className: "horizontal-sl-icon-bc bc-i-default-icon bc-i-".concat(item.sportSlug) }, void 0), _jsxs("p", __assign({ className: "horizontal-sl-title-bc" }, { children: [item.sportName.replace('Hokeyi', 'Hok.').replace('Sporları', 'Spr.'), " "] }), void 0), _jsx("span", __assign({ className: "horizontal-sl-count-bc" }, { children: params.eventType == 'canli' ? item.liveEventCount : item.count }), void 0)] }), "sports-" + key);
                                            }
                                        }) }), void 0) }), void 0), params.competitionId == null ?
                                _jsx(_Fragment, { children: params.eventType == 'canli' ?
                                        _jsxs("div", __assign({ className: "live-page-sections-bc ext-2" }, { children: [_jsxs("div", __assign({ className: "market-filter-bc" }, { children: [_jsx("div", __assign({ className: "market-filter-s-bc" }, { children: _jsx("div", __assign({ className: "form-control-bc select s-small filled" }, { children: _jsxs("label", __assign({ className: "form-control-label-bc inputs" }, { children: [_jsx("select", __assign({ className: "form-control-select-bc ellipsis", onChange: function (event) {
                                                                                mobileHighLightedMarket.selected = highLightedMarketCategories["".concat(sportSlug).concat(params.eventType == 'canli' ? '-canli' : '') || 'futbol'].find(function (el) { return el.live === event.target.value; });
                                                                                forceUpdate();
                                                                            } }, { children: highLightedMarketCategories["".concat(sportSlug).concat(params.eventType == 'canli' ? '-canli' : '') || 'futbol'].map(function (marketGroup, key) {
                                                                                return _jsx("option", __assign({ value: marketGroup.live }, { children: marketGroup.name }), void 0);
                                                                            }) }), void 0), _jsx("i", { className: "form-control-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "market-filter-cell-bc" }, { children: (_a = mobileHighLightedMarket === null || mobileHighLightedMarket === void 0 ? void 0 : mobileHighLightedMarket.selected) === null || _a === void 0 ? void 0 : _a.labels.map(function (el, keyH) {
                                                                return _jsx("div", __assign({ className: "market-filter-info-bc" }, { children: el }), "highlighted-" + keyH);
                                                            }) }), void 0)] }), void 0), sportsSummery.filter(function (el) { return el.sportSlug == sportSlug; }).map(function (item, key) {
                                                    var sportsCompetitions = generateCompetitionGroupList(shownSportsBook, [item.sportSlug]);
                                                    return _jsx(_Fragment, { children: sportsCompetitions.map(function (itemComp, key) {
                                                            return _jsx(_Fragment, { children: Object.entries(itemComp.competitions).map(function (comp, key) {
                                                                    var customGroupHeader = comp[0] + '' + itemComp.competitionGroupName + '_' + itemComp.sportId;
                                                                    return (_jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(selectedLiveComp.includes(customGroupHeader) ? "" : 'active selected') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                                                    if (selectedLiveComp.includes(customGroupHeader)) {
                                                                                        selectedLiveComp = selectedLiveComp.toSpliced(selectedLiveComp.indexOf(customGroupHeader), 1);
                                                                                    }
                                                                                    else {
                                                                                        selectedLiveComp.push(customGroupHeader);
                                                                                    }
                                                                                    forceUpdate();
                                                                                }, className: "sp-s-l-head-bc", title: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, { children: [_jsx("i", { className: "sp-s-l-h-flag-bc flag-bc ".concat(itemComp.competitionGroupName === "IIIskoçya" ? 'scotland' : itemComp.countryName.toLowerCase()) }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsxs("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: [itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ'), " ", comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri')] }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: comp[1].length }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: comp[1].map(function (event, __key) {
                                                                                    return _jsxs("div", __assign({ className: "c-segment-holder-bc single-g-info-bc" }, { children: [_jsxs("div", __assign({ className: "sw-container-bc ", style: { transform: "translateX(0px)" } }, { children: [_jsx("div", __assign({ className: "c-segment-holder-bc markets " }, { children: _jsxs("div", __assign({ className: "c-row-bc bordered" }, { children: [_jsxs("div", __assign({ className: "c-teams-bc", onClick: function () {
                                                                                                                        navigate(base + '/sports/' + event.sportSlug + '/' + params.eventType + '/' + event.competitionId + '/' + event.eventId);
                                                                                                                    } }, { children: [_jsxs("div", __assign({ className: "c-teams-row-bc" }, { children: [_jsx("span", __assign({ className: "c-team-info-team-bc team", title: event.eventParticipant[0].participantName }, { children: event.eventParticipant[0].participantName }), void 0), event.currentScore &&
                                                                                                                                    _jsx("b", __assign({ className: "c-team-info-scores-bc" }, { children: event.currentScore.split(':')[0] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "c-teams-row-bc" }, { children: [_jsx("span", __assign({ className: "c-team-info-team-bc team", title: event.eventParticipant[1].participantName }, { children: event.eventParticipant[1].participantName }), void 0), event.currentScore &&
                                                                                                                                    _jsx("b", __assign({ className: "c-team-info-scores-bc" }, { children: event.currentScore.split(':')[1] }), void 0)] }), void 0)] }), void 0), _jsx(HighlightedMarkets, { style: "border:1px solid #000;", event: event, highlightedMarkets: event.highlightedMarkets }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "c-segment-holder-bc info", onClick: function () {
                                                                                                            navigate(base + '/sports/' + event.sportSlug + '/' + params.eventType + '/' + event.competitionId + '/' + event.eventId);
                                                                                                        } }, { children: _jsx("div", __assign({ className: "c-info-bc" }, { children: event.currentScore ?
                                                                                                                _jsxs("div", __assign({ className: "c-info-content-bc justify-content-between" }, { children: [_jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("i", { className: "info-live-prematch-icon-bc bc-i-live" }, void 0), _jsxs("div", __assign({ className: "c-info-score-wrapper" }, { children: [_jsx("span", __assign({ className: "c-info-score-bc" }, { children: getMatchStatus(event) }), void 0), _jsxs("span", __assign({ className: "c-info-score-bc fixed-direction", title: "" }, { children: [event.currentScore, ", ", event.scores && _jsxs(_Fragment, { children: ["(", event.scores.homeTeam.firstHalf, ":", event.scores.awayTeam.firstHalf, ")"] }, void 0), " ", event.matchPlayTime, "`"] }), void 0)] }), void 0)] }), void 0), _jsxs("span", __assign({ className: "c-info-icon-bc c-info-markets-count-bc" }, { children: ["+", event.marketOutcomeCount] }), void 0)] }), void 0) :
                                                                                                                _jsxs("div", __assign({ className: "c-info-content-bc justify-content-between" }, { children: [_jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("i", { className: "info-live-prematch-icon-bc bc-i-prematch" }, void 0), _jsx("time", __assign({ className: "c-info-time-bc" }, { children: timeFormat(event.eventDate) }), void 0), _jsx("div", { className: "c-info-score-wrapper" }, void 0)] }), void 0), _jsxs("span", __assign({ className: "c-info-icon-bc c-info-markets-count-bc" }, { children: ["+", event.marketOutcomeCount] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "swipe-fav-container-bc ", style: { transform: "translateX(60px)" } }, { children: _jsx("i", { className: "c-info-icon-bc bc-i-favorite" }, void 0) }), void 0)] }), key + "-event-side-" + __key);
                                                                                    /*<div className="market-game-section "
                                                                                                onClick={() => {
                                                                                                    eventDetailSubject.next(event)
                                                                                                }}>
                                                                                        <p className="market-game-team">
                                                                                        <span
                                                                                            className="market-game-team-name ellipsis">{event.eventParticipant[0].participantName}</span>
                                                                                            {event.currentScore &&
                                                                                                <b className="market-game-odd">{event.currentScore.split(':')[0]}</b>}
                                                                                        </p>
                                                                                        <p className="market-game-team">
                                                                                        <span
                                                                                            className="market-game-team-name ellipsis">{event.eventParticipant[1].participantName}</span>
                                                                                            {event.currentScore &&
                                                                                                <b className="market-game-odd">{event.currentScore.split(':')[1]}</b>}
                                                                                        </p>
                                                                                        <div className="market-game-part-container">
                                                                                        <span
                                                                                            className="market-game-part">{event.matchStatus}</span>
                                                                                            <b>+{event.marketOutcomeCount}</b>
                                                                                            <i className=" bc-i-favorite "></i>
                                                                                        </div>
                                                                                        <div
                                                                                            className="market-game-additional-info-container">
                                                                                        <span
                                                                                            className="market-game-additional-info">{event.currentScore}, {event.scores && <>({event.scores.homeTeam.firstHalf}:{event.scores.awayTeam.firstHalf})</>} {event.matchPlayTime}`</span>
                                                                                            <time
                                                                                                className="market-game-additional-info-time">{event.eventDate.split("T")[1].substring(0, 5)}
                                                                                            </time>
                                                                                        </div>
                                                                                        <HighlightedMarketsCard event={event}
                                                                                                                highlightedMarkets={event.highlightedMarkets}></HighlightedMarketsCard>
                
                                                                                    </div>*/
                                                                                }) }), void 0)] }), "eventSporMenu2".concat(key)));
                                                                }) }, void 0);
                                                        }) }, void 0);
                                                })] }), void 0)
                                        : _jsx("div", __assign({ className: "pp-sport-list-holder-bc" }, { children: _jsx("div", __assign({ className: "left-menu-scroll" }, { children: sportsSummery.filter(function (el) { return el.sportSlug == sportSlug; }).map(function (item, key) {
                                                    var sportsCompetitions = generateCompetitionGroupList(shownSportsBook, ['countryName']);
                                                    return _jsx(_Fragment, { children: sportsCompetitions.map(function (itemComp, key) {
                                                            return _jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(selectedComp != null && selectedComp.competitionGroupName == itemComp.competitionGroupName && selectedComp.sportId == itemComp.sportId ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                                            if (selectedComp == null) {
                                                                                setSelectedComp(itemComp);
                                                                            }
                                                                            else {
                                                                                if (selectedComp.competitionGroupName == itemComp.competitionGroupName && selectedComp.sportId == itemComp.sportId) {
                                                                                    setSelectedComp(null);
                                                                                }
                                                                                else {
                                                                                    setSelectedComp(itemComp);
                                                                                }
                                                                            }
                                                                            forceUpdate();
                                                                        }, className: "sp-s-l-head-bc", title: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, { children: [_jsx("i", { className: "sp-s-l-h-flag-bc flag-bc ".concat(itemComp.competitionGroupName === "IIIskoçya" ? 'scotland' : itemComp.countryName.toLowerCase()) }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: itemComp.count }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: Object.entries(itemComp.competitions).map(function (comp, _key) {
                                                                            return (_jsxs("div", __assign({ onClick: function () {
                                                                                    sportsbookFilter.selectedCompetitionGroup = itemComp;
                                                                                    navigate(base + "/sports/" + item.sportSlug + '/tumu/' + comp[1][0].competitionId);
                                                                                    window.scrollTo(0, 0);
                                                                                }, className: "sp-sub-list-bc " }, { children: [_jsxs("div", __assign({ className: "sp-s-l-head-bc", title: "" }, { children: [_jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri') }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: comp[1].length }), void 0)] }), void 0), _jsx("div", { className: "sp-s-l-b-content-bc" }, void 0)] }), "competitions-" + _key));
                                                                        }) }), void 0)] }), "eventSporMenu2".concat(key));
                                                        }) }, void 0);
                                                }) }), void 0) }), void 0) }, void 0) : _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "back-nav-bc" }, { children: [_jsx("i", { className: "back-nav-icon-bc bc-i-round-arrow-left", onClick: function () {
                                                    navigate(base + "/sports/" + sportSlug + '/tumu/');
                                                    setFilterCompetitions("clear");
                                                } }, void 0), sportsbookFilter.selectedCompetitionGroup.competitionGroupName && _jsxs("span", __assign({ className: "back-nav-title-bc ellipsis" }, { children: [sportsbookFilter.selectedCompetitionGroup.sportSlug, " / ", sportsbookFilter.selectedCompetitionGroup.competitionGroupName] }), void 0)] }), void 0), sportsbookFilter.selectedCompetitionGroup.competitionGroupName &&
                                        _jsxs("div", __assign({ className: "comp-name-fav-row-bc " }, { children: [_jsx("div", __assign({ className: "comp-select-row-bc" }, { children: _jsx("div", __assign({ className: "form-control-bc select  filled" }, { children: _jsxs("label", __assign({ className: "form-control-label-bc inputs" }, { children: [_jsx("select", __assign({ className: "form-control-select-bc ellipsis", onChange: function (el) {
                                                                        navigate(base + "/sports/" + sportSlug + '/tumu/' + el.target.value);
                                                                    } }, { children: Object.keys(sportsbookFilter.selectedCompetitionGroup.competitions).map(function (comp, key) {
                                                                        return _jsx("option", __assign({ value: sportsbookFilter.selectedCompetitionGroup.competitions[comp][0].competitionId }, { children: comp }), void 0);
                                                                    }) }), void 0), _jsx("i", { className: "form-control-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0) }), void 0) }), void 0), _jsx("i", { className: "comp-fav-icon-bc bc-i-favorite " }, void 0)] }), void 0), _jsx("div", __assign({ className: "market-filter-scrollable-navigation-bc-holder" }, { children: _jsx("div", __assign({ className: "horizontal-sl-list-container", "data-scroll-lock-scrollable": "" }, { children: _jsx("div", __assign({ className: "horizontal-sl-list " }, { children: highLightedMarketCategories["".concat(params.sportSlug).concat(params.eventType == 'canli' ? '-canli' : '') || 'futbol'].map(function (marketGroup, key) {
                                                    return _jsx("div", __assign({ title: "Ma\u00E7 Sonucu", "data-badge": "", onClick: function () {
                                                            mobileHighLightedMarket.selected = marketGroup;
                                                            forceUpdate();
                                                        }, className: "horizontal-sl-item-bc accordion-button  ".concat(mobileHighLightedMarket.selected && mobileHighLightedMarket.selected.pre === marketGroup.pre ? 'active' : '') }, { children: _jsx("p", __assign({ className: "horizontal-sl-title-bc" }, { children: marketGroup.name }), void 0) }), key);
                                                }) }), void 0) }), void 0) }), void 0), _jsx(Eventlist, { openEventDetail: openEventDetail, sportsbookFilter: sportsbookFilter, params: params, shownSportsBook: shownSportsBook }, void 0)] }, void 0)] }), void 0), _jsx(EventDetail, { eventType: params.eventType, sportSlug: params.sportSlug, closeEventDetail: closeEventDetail, openEventDetail: openEventDetail }, void 0)] }, void 0), _jsx(Modal, __assign({ isOpen: highLightedMarketModalIsOpened, onRequestClose: function () {
                    setHighLightedMarketModalIsOpened(false);
                    forceUpdate();
                }, ariaHideApp: false, style: {
                    content: {
                        background: "rgba(var(--header), 1)",
                        padding: "0",
                        left: "calc( 50% - 160px )",
                        top: "120px",
                        width: "400px",
                        height: "440px"
                    }
                } }, { children: _jsxs("div", __assign({ className: "popup-inner-bc" }, { children: [_jsx("i", { onClick: function () {
                                setHighLightedMarketModalIsOpened(false);
                            }, className: "e-p-close-icon-bc bc-i-close-remove" }, void 0), _jsx("div", __assign({ className: "entrance-popup-bc sign-in", style: { height: '440px' } }, { children: _jsx("div", __assign({ className: "e-p-content-holder-bc" }, { children: _jsxs("div", __assign({ className: "e-p-content-bc" }, { children: [_jsx("div", __assign({ className: "e-p-header-bc" }, { children: _jsx("div", __assign({ className: "sg-n-text-row-2-bc m-0" }, { children: "Marketlerini Se\u00E7" }), void 0) }), void 0), _jsx("div", __assign({ className: "e-p-body-bc" }, { children: _jsxs("form", __assign({ className: "entrance-form-bc login popup", "data-scroll-lock-scrollable": "" }, { children: [_jsxs("div", __assign({ className: "form-sign-bc" }, { children: [highLightedMarketCategories["".concat(params.sportSlug).concat(params.eventType == 'canli' ? '-canli' : '') || 'futbol'].map(function (marketGroup, key) {
                                                                return (_jsx("div", __assign({ className: "entrance-f-item-bc entrance-f-item-checkbox-bc" }, { children: _jsx("div", __assign({ className: "checkbox-control-bc" }, { children: _jsxs("label", __assign({ className: "checkbox-control-content-bc" }, { children: [_jsx("input", { className: "checkbox-control-input-bc", type: "checkbox", checked: myHighLightedMarketGroups["".concat(params.sportSlug).concat(params.eventType == 'canli' ? '-canli' : '') || 'futbol'].indexOf(marketGroup) !== -1, onChange: function () {
                                                                                        setHighLightedSelectedList(marketGroup, params.sportSlug);
                                                                                    } }, void 0), _jsx("i", { className: "checkbox-control-icon-bc bc-i-checked" }, void 0), _jsx("p", __assign({ className: "checkbox-control-text-bc" }, { children: marketGroup.name }), void 0)] }), void 0) }), void 0) }), key));
                                                            }), _jsx("div", __assign({ className: "entrance-form-actions-holder-bc login-ext-1" }, { children: _jsx("div", __assign({ className: "entrance-form-action-item-bc right" }, { children: _jsx("button", __assign({ onClick: function () {
                                                                            setHighLightedMarketModalIsOpened(false);
                                                                        }, className: "btn a-color ", type: "button", title: "G\u0130R\u0130\u015E" }, { children: _jsx("span", { children: "Tamam" }, void 0) }), void 0) }), void 0) }), void 0)] }), void 0), _jsx("div", { className: "form-sign-in-slider-wrapper" }, void 0)] }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0)] }, void 0));
};
export default SportsBook;
var searchTimer;
export function MobileSearchBar() {
    var _a = useState(false), modalIsOpen = _a[0], setIsOpen = _a[1];
    var _b = useState(false), openMobileSearch = _b[0], setOpenMobileSearch = _b[1];
    var navigate = useNavigate();
    var _c = useState(''), input = _c[0], setInput = _c[1];
    var _d = useState([]), searchResult = _d[0], setSearchResult = _d[1];
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    var changeHandle = function (keyword) {
        clearTimeout(searchTimer);
        if (keyword.length > 2) {
            searchTimer = setTimeout(function () {
                if (keyword.toLowerCase().includes('para çek')) {
                    navigate(base + '/hesabim/para-cek');
                }
                else if (keyword.toLowerCase().includes('banka hesa')) {
                    navigate(base + '/hesabim/banka-hesaplarim');
                }
                else if (keyword.toLowerCase().includes('destek tale')) {
                    navigate(base + '/hesabim/destek-taleplerim/beklemede');
                }
                else {
                    if (keyword.toLowerCase().split(' ').includes('lig') ||
                        keyword.toLowerCase().split(' ').includes('ligi') ||
                        keyword.toLowerCase().split(' ').includes('bundesliga') ||
                        keyword.toLowerCase().split(' ').includes('lig1') ||
                        keyword.toLowerCase().split(' ').includes('Ligue 1') ||
                        keyword.toLowerCase().split(' ').includes('concacaf') ||
                        keyword.toLowerCase().split(' ').includes('uluslararası') ||
                        keyword.toLowerCase().split(' ').includes('uluslar arası') ||
                        keyword.toLowerCase().split(' ').includes('bölgesellig') ||
                        keyword.toLowerCase().split(' ').includes('euroleague') ||
                        keyword.toLowerCase().split(' ').includes('laliga')) {
                        var competitionResult = [];
                        keyword = keyword.toLowerCase().replace(' ligi', '').replace(' lig', '');
                        var result = _.orderBy(sportsbookList.filter(function (x) { return Number(x.eventType) !== 2; }).filter(function (el) {
                            var isMatch = 0;
                            if (keyword.length > 2) {
                                for (var _i = 0, _a = keyword.split(' '); _i < _a.length; _i++) {
                                    var k = _a[_i];
                                    // console.log(el.competitionName.toLowerCase(), k.toLowerCase(), el.competitionName.toLowerCase().includes(k.toLowerCase()))
                                    if (el.competitionName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                    if (el.competitionGroupName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                }
                                if (isMatch > 0) {
                                    el.searchScore = isMatch;
                                    return el;
                                }
                            }
                            return false;
                        }), ['competitionPriority', 'searchScore'], ['asc', 'desc']);
                        var _loop_1 = function (event_1) {
                            if (!competitionResult.find(function (x) {
                                return x.name === event_1.competitionName;
                            })) {
                                competitionResult.push({
                                    name: event_1.competitionName,
                                    sportSlug: event_1.sportSlug,
                                    competitionId: event_1.competitionId,
                                    eventType: event_1.eventType,
                                    count: result.filter(function (e) { return e.competitionName === event_1.competitionName; }).length
                                });
                            }
                        };
                        for (var _i = 0, result_1 = result; _i < result_1.length; _i++) {
                            var event_1 = result_1[_i];
                            _loop_1(event_1);
                        }
                        if (competitionResult.length === 1) {
                            var competition = competitionResult[0];
                            setInput('');
                            navigate(base + '/sports/' + competition.sportSlug + '/' + (Number(competition.eventType) === 2 ? 'uzunvadeli' : 'tumu') + '/' + competition.competitionId);
                        }
                        else if (result.length > 1) {
                            setSearchResult(competitionResult.slice(0, 10));
                            setInput('');
                            setIsOpen(true);
                        }
                        else {
                            modalServiceSubject.next({
                                title: 'Arama Sonucu',
                                content: 'Lütfen aramak istediğiniz takım ismini ya da lig ismi doğru giriniz',
                                confirm: {
                                    sure: {
                                        label: 'Temizle ve Tekrarla',
                                        action: function () {
                                            setInput('');
                                            changeHandle('');
                                            // @ts-ignore
                                            document.getElementById('seachBar').focus();
                                        },
                                    }
                                },
                                timeout: 3000,
                            });
                        }
                    }
                    else {
                        var result = _.orderBy(sportsbookList.filter(function (x) { return Number(x.eventType) !== 2; }).filter(function (el) {
                            var isMatch = 0;
                            if (keyword.length > 2) {
                                for (var _i = 0, _a = keyword.split(' '); _i < _a.length; _i++) {
                                    var k = _a[_i];
                                    if (el.eventName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                }
                                if (isMatch > 0) {
                                    el.searchScore = isMatch;
                                    return el;
                                }
                            }
                            return false;
                        }), ['searchScore'], ['desc']);
                        if (result.length === 1) {
                            var event_2 = result[0];
                            setInput('');
                            navigate(base + '/sports/' + event_2.sportSlug + '/' + (Number(event_2.eventType) === 2 ? 'uzunvadeli' : 'tumu') + '/' + event_2.competitionId + '/' + event_2.eventId);
                        }
                        else if (result.length > 1) {
                            setSearchResult(result.slice(0, 5));
                            setInput('');
                            setIsOpen(true);
                        }
                        else {
                            modalServiceSubject.next({
                                title: 'Arama Sonucu',
                                content: 'Lütfen aramak istediğiniz takım ismini ya da lig ismi doğru giriniz',
                                timeout: 3000,
                                confirm: {
                                    sure: {
                                        label: 'Temizle ve Tekrarla',
                                        action: function () {
                                            setInput('');
                                            changeHandle('');
                                            // @ts-ignore
                                            document.getElementById('seachBar').focus();
                                        },
                                    }
                                },
                            });
                        }
                    }
                }
            }, 1000);
        }
        else {
        }
        setInput(keyword);
    };
    function closeModal() {
        setIsOpen(false);
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "sport-search-bc ".concat(openMobileSearch ? 'active' : '') }, { children: [openMobileSearch && _jsx("input", { placeholder: "Bir yar\u0131\u015Fma veya tak\u0131m aray\u0131n", className: "ss-input-bc", type: "search", autoComplete: "off", value: input, onChange: function (e) { return changeHandle(e.target.value); } }, void 0), _jsxs("div", __assign({ className: "ss-icon-holder-bc", onClick: function () {
                            setOpenMobileSearch(function (prevState) { return !prevState; });
                        } }, { children: [openMobileSearch && _jsx("i", { className: "ss-icon-bc bc-i-close-remove" }, void 0), !openMobileSearch && _jsx("i", { className: "ss-icon-bc bc-i-search" }, void 0)] }), void 0)] }), void 0), modalIsOpen && _jsx("div", { children: _jsxs("div", __assign({ className: "sport-search-result-bc", "data-scroll-lock-scrollable": "", style: { height: "calc(-158px + 100vh)" } }, { children: [_jsxs("div", __assign({ className: "sport-search-result-header-bc" }, { children: [_jsxs("span", __assign({ className: "sport-search-result-title-bc ellipsis" }, { children: ["Arama Sonu\u00E7lar\u0131 (", searchResult.length, ")"] }), void 0), _jsx("i", { onClick: function () { return closeModal(); }, className: "bc-i-close-remove search-close-btn" }, void 0)] }), void 0), _jsx("ul", __assign({ className: "sport-search-result-body-bc", "data-scroll-lock-scrollable": "" }, { children: searchResult.map(function (result, key) {
                                return _jsxs("div", __assign({ className: "sports-group-bc" }, { children: [_jsxs("div", __assign({ className: "sg-head-bc  ".concat(result.sportSlug, "-bg") }, { children: [_jsx("i", { className: "sg-type-icon-bc bc-i-".concat(result.sportSlug) }, void 0), _jsx("span", __assign({ className: "sg-title-bc ellipsis" }, { children: result.sportName }), void 0)] }), void 0), _jsx("div", __assign({ className: "sg-body-bc" }, { children: _jsxs("li", __assign({ className: "sport-search-result-item-bc", onClick: function () {
                                                    if (result.count) {
                                                        navigate(base + '/sports/' + result.sportSlug + '/' + (result.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + result.competitionId);
                                                    }
                                                    else {
                                                        navigate(base + '/sports/' + result.sportSlug + '/' + (result.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + result.competitionId + '/' + result.eventId);
                                                    }
                                                    setFilterCompetitions(result.competitionId);
                                                    closeModal();
                                                } }, { children: [_jsx("p", __assign({ className: "s-g-competition-n-bc" }, { children: result.competitionName }), void 0), _jsx("p", { children: result.name ? result.name : result.eventName }, void 0), _jsx("p", __assign({ className: "s-g-r-time-bc" }, { children: _jsx("time", __assign({ className: "" }, { children: dateTimeFormat(result.eventDate) }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0);
                            }) }), void 0)] }), void 0) }, void 0)] }, void 0));
}
