var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { eventSliderList } from "../../../store/sportsbook.store";
import { useForceUpdate } from "../../../lib/misc.functions";
import Carousel from "react-elastic-carousel";
import { Subject } from "rxjs";
import { useNavigate } from "react-router";
import slider1 from "../../../assets/images/web-slide.jpg";
import slider2 from "../../../assets/images/mobile-slide.jpg";
import { isMobileView } from "../../../app";
export var eventSliderSubject = new Subject();
export default function Slider(props) {
    var sliderType = props.sliderType;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var _a = useState(0), sliderIndex = _a[0], setSliderIndex = _a[1];
    var _b = useState(0), mobileSliderIndex = _b[0], setMobileSliderIndex = _b[1];
    var carouselRef = useRef(null);
    var carouselRefMobile = useRef(null);
    useEffect(function () {
        var eventSliderSubject_subscription = eventSliderSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            eventSliderSubject_subscription.unsubscribe();
        };
    }, []);
    var breakPoints = [
        { width: 1, itemsToShow: Number(sliderType) === 0 ? 1 : 2 },
        { width: 520, itemsToShow: Number(sliderType) === 0 ? 1 : 2 },
        { width: 750, itemsToShow: Number(sliderType) === 0 ? 1 : 3 },
        { width: 1200, itemsToShow: Number(sliderType) === 0 ? 1 : 6 },
    ];
    var sliderList = eventSliderList.filter(function (el) { return Number(el.slide_type) === 2; });
    if (Number(sliderType) === 0) {
        return (_jsxs("div", __assign({ className: "hm-row-bc  has-slider", style: { gridTemplateColumns: isMobileView ? '12fr' : '9fr 3fr' } }, { children: [!isMobileView && _jsxs("div", __assign({ className: "slider-bc" }, { children: [_jsx(Carousel, __assign({ ref: carouselRef, autoPlaySpeed: 5000, isRTL: false, enableAutoPlay: true, breakPoints: breakPoints, onChange: function (currentItem, pageIndex) {
                                setSliderIndex(pageIndex);
                            }, pagination: false, showArrows: false }, { children: sliderList.map(function (el) {
                                return _jsx("div", __assign({ className: "sdr-item-holder-bc" }, { children: _jsx("a", __assign({ target: "_self", className: "sdr-item-bc", href: "" }, { children: _jsx("img", { src: slider1, loading: "lazy", className: "sdr-image-bc", alt: "", title: "% 50 SLOT" }, void 0) }), void 0) }), void 0);
                            }) }), void 0), _jsxs("div", __assign({ className: "carousel-count-arrow-container with-count" }, { children: [_jsx("i", { className: "carousel-arrow-left bc-i-small-arrow-left ", onClick: function () {
                                        carouselRef.current.slidePrev();
                                    } }, void 0), _jsxs("div", __assign({ className: "carousel-count-arrow" }, { children: [sliderIndex + 1, " / ", sliderList.length] }), void 0), _jsx("i", { className: "carousel-arrow-right bc-i-small-arrow-right ", onClick: function () {
                                        carouselRef.current.slideNext();
                                    } }, void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "slider-bc" }, { children: [isMobileView && _jsxs("div", __assign({ className: "carousel-count" }, { children: [sliderIndex + 1, " / 2"] }), void 0), _jsx(Carousel, __assign({ ref: carouselRefMobile, autoPlaySpeed: 5000, isRTL: false, enableAutoPlay: true, breakPoints: breakPoints, onChange: function (currentItem, pageIndex) {
                                setMobileSliderIndex(pageIndex);
                            }, pagination: false, showArrows: false }, { children: sliderList.map(function (el) {
                                return _jsx("div", __assign({ className: "sdr-item-holder-bc" }, { children: _jsx("a", __assign({ target: "_self", className: "sdr-item-bc", href: "" }, { children: _jsx("img", { src: slider2, loading: "lazy", className: "sdr-image-bc", alt: "", title: "% 50 SLOT" }, void 0) }), void 0) }), void 0);
                            }) }), void 0), !isMobileView && _jsxs("div", __assign({ className: "carousel-count-arrow-container with-count" }, { children: [_jsx("i", { className: "carousel-arrow-left bc-i-small-arrow-left ", onClick: function () {
                                        carouselRefMobile.current.slidePrev();
                                    } }, void 0), _jsxs("div", __assign({ className: "carousel-count-arrow" }, { children: [mobileSliderIndex + 1, " / ", sliderList.length] }), void 0), _jsx("i", { className: "carousel-arrow-right bc-i-small-arrow-right ", onClick: function () {
                                        carouselRefMobile.current.slideNext();
                                    } }, void 0)] }), void 0)] }), void 0)] }), void 0));
    }
    else {
        return (_jsxs("div", __assign({ className: "hm-row-bc  has-slider " }, { children: [_jsx("div", { className: "slider-bc" }, void 0), _jsx("div", { className: "slider-bc" }, void 0)] }), void 0));
    }
}
