var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AccountTransactionApi } from "./account.transactions.api";
import { Storage } from "../../../../lib/localstorege.service";
import { modalServiceSubject } from "../../../../components/modal/modal";
import '../../account.css';
import { initialLeftMenu } from "../../../../lib/misc.functions";
import { doLogout } from '../../../../services/login.services';
import { useAuthContext } from "../../../../store/login.store";
import paths from '../../../../app/paths';
import { betSlipVisibleSubject } from '../../../../store/betslip.store';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function ChangePassword() {
    var token = Storage.get('token');
    var setIsAuthenticated = useAuthContext().setIsAuthenticated;
    var navigate = useNavigate();
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var base = '/' + i18n.resolvedLanguage;
    useEffect(function () {
        initialLeftMenu(false);
    }, []);
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, control = _b.control, getValues = _b.getValues, errors = _b.formState.errors;
    var onSubmit = function (data) {
        callChangePasswordApi(data);
    };
    var callChangePasswordApi = function (data) {
        data.token = token;
        AccountTransactionApi.changePassword(data).then(function (result) {
            if (result.status) {
                doLogout();
                var pathname = window.location.pathname.slice(1);
                if (pathname === paths.LOGIN || pathname === '') {
                    pathname = '/';
                }
                setIsAuthenticated(false);
                betSlipVisibleSubject.next(false);
                navigate(base + '/giris-yap');
                modalServiceSubject.next({
                    title: "Güncellendi!",
                    content: "Şifre başarıyla güncellendi.",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    var togglePassword = function (id) {
        var elementInput = document.getElementById(id + "Input");
        var element = document.getElementById(id);
        if (elementInput.type == 'password') {
            elementInput.type = "text";
            element.classList.remove('bc-i-eye-hidden');
            element.classList.add('bc-i-eye');
        }
        else {
            elementInput.type = "password";
            element.classList.add('bc-i-eye-hidden');
            element.classList.remove('bc-i-eye');
        }
    };
    return (_jsxs("div", __assign({ className: "my-profile-info-block" }, { children: [_jsx("div", __assign({ className: "overlay-header" }, { children: t('changePassword.title') }), void 0), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "u-i-e-p-p-content-bc u-i-common-content" }, { children: [_jsx("div", __assign({ className: "u-i-p-control-item-holder-bc" }, { children: _jsxs("div", __assign({ className: "form-control-bc default has-icon ".concat(errors.current_pswd ? 'invalid' : 'valid') }, { children: [_jsxs("label", __assign({ className: "form-control-label-bc inputs" }, { children: [_jsx("input", __assign({ type: "password", id: "defaultPasswordInput" }, register('current_pswd', { required: true, minLength: 6, }), { className: "form-control-input-bc", autoComplete: "current-password", step: "0" }), void 0), _jsx("i", { className: "form-control-input-stroke-bc" }, void 0), _jsx("span", __assign({ className: "form-control-title-bc ellipsis" }, { children: t('changePassword.currentPswd') }), void 0), _jsx("i", { onClick: function () { return togglePassword("defaultPassword"); }, id: "defaultPassword", className: "form-control-icon-bc bc-i-eye-hidden" }, void 0)] }), void 0), _jsxs("div", __assign({ className: "form-control-message-holder-bc" }, { children: [errors.current_pswd && errors.current_pswd.type === "required" &&
                                            _jsx("span", __assign({ className: "form-control-message-bc" }, { children: t('changePassword.currentError1') }), void 0), errors.current_pswd && errors.current_pswd.type === "minLength" &&
                                            _jsx("span", __assign({ className: "form-control-message-bc" }, { children: t('changePassword.currentError2') }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "u-i-p-control-item-holder-bc" }, { children: _jsxs("div", __assign({ className: "form-control-bc default has-icon ".concat(errors.new_pswd ? 'invalid' : 'valid') }, { children: [_jsxs("label", __assign({ className: "form-control-label-bc inputs" }, { children: [_jsx(Controller, { name: "new_pswd", control: control, rules: {
                                                required: "Şifre belirlemelisiniz",
                                            }, render: function () { return _jsx("input", __assign({ id: "newPasswordInput" }, register('new_pswd', { required: true, minLength: 6, }), { name: "new_pswd", type: "password", className: "form-control-input-bc", autoComplete: "current-password" }), void 0); } }, void 0), _jsx("i", { className: "form-control-input-stroke-bc" }, void 0), _jsx("span", __assign({ className: "form-control-title-bc ellipsis" }, { children: t('changePassword.newPswd') }), void 0), _jsx("i", { onClick: function () { return togglePassword("newPassword"); }, id: "newPassword", className: "form-control-icon-bc bc-i-eye-hidden" }, void 0)] }), void 0), _jsxs("div", __assign({ className: "form-control-message-holder-bc" }, { children: [errors.new_pswd && errors.new_pswd.type === "required" &&
                                            _jsx("span", __assign({ className: "form-control-message-bc" }, { children: t('changePassword.currentError1') }), void 0), errors.new_pswd && errors.new_pswd.type === "minLength" &&
                                            _jsx("span", __assign({ className: "form-control-message-bc" }, { children: t('changePassword.currentError2') }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "u-i-p-control-item-holder-bc" }, { children: _jsxs("div", __assign({ className: "form-control-bc default has-icon ".concat(errors.new_pswd_repeat ? 'invalid' : 'valid') }, { children: [_jsxs("label", __assign({ className: "form-control-label-bc inputs" }, { children: [_jsx(Controller, { name: "new_pswd_repeat", control: control, rules: {
                                                required: t("error1"),
                                                //@ts-ignore
                                                validate: function (value) {
                                                    if (value === getValues()["new_pswd"]) {
                                                        return true;
                                                    }
                                                    else {
                                                        return (_jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre tekrar\u0131, \u015Fifre ile e\u015Fle\u015Fmelidir." }), void 0));
                                                    }
                                                }
                                            }, render: function () { return _jsx("input", __assign({ id: "newPassword2Input" }, register('new_pswd_repeat', { required: true }), { name: "new_pswd_repeat", type: "password", className: "form-control-input-bc" }), void 0); } }, void 0), _jsx("i", { className: "form-control-input-stroke-bc" }, void 0), _jsx("span", __assign({ className: "form-control-title-bc ellipsis" }, { children: t('changePassword.newPswdRpt') }), void 0), _jsx("i", { onClick: function () { return togglePassword("newPassword2"); }, id: "newPassword2", className: "form-control-icon-bc bc-i-eye-hidden" }, void 0)] }), void 0), _jsxs("div", __assign({ className: "form-control-message-holder-bc" }, { children: [errors.new_pswd_repeat && errors.new_pswd_repeat.type === "required" &&
                                            _jsx("span", __assign({ className: "form-control-message-bc" }, { children: t('changePassword.currentError1') }), void 0), errors.new_pswd_repeat && errors.new_pswd_repeat.type === "minLength" &&
                                            _jsx("span", __assign({ className: "form-control-message-bc" }, { children: t('changePassword.currentError2') }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "u-i-p-c-footer-bc" }, { children: _jsx("button", __assign({ className: "btn a-color right-aligned ", type: "submit", title: "\u015E\u0130FRE DE\u011E\u0130\u015ET\u0130R" }, { children: _jsx("span", { children: t('changePassword.title') }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0));
}
