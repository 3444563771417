var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { ApiService } from "../../lib/http.service";
import { Storage } from "../../lib/localstorege.service";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { isMobileView } from "../../app";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../store/login.store";
export function GameDetail() {
    var _this = this;
    var _a = useAuthContext(), isAuthenticated = _a.isAuthenticated, setIsAuthenticated = _a.setIsAuthenticated;
    var iframeContainerRef = useRef(null);
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    var navigate = useNavigate();
    var token = Storage.get('token');
    var params = useParams();
    useEffect(function () {
        betSlipVisibleSubject.next(false);
        if (!isMobileView) {
            var root = document.getElementById('root');
            root.classList.remove('is-home-page');
            root.classList.add("page-full-size");
            root.classList.add("casino-slots");
            root.classList.add("compact-footer");
        }
    }, []);
    var playGame = function () { return __awaiter(_this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService
                            .start('post', 'https://api.1xwon.com/games/tbs2/vendor/auth/', {
                            "token": token,
                            "game_code": params.gameCode
                        }, true, 30000)
                            .then(function (response) {
                            if (response.status && response.data) {
                                if (iframeContainerRef.current) {
                                    iframeContainerRef.current.src = response.data.url;
                                }
                            }
                        })
                            .catch(function (e) {
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        // var gameCode = props.gameCode;
        // var game = gameList.find((g: any) => g.id.toString() == gameCode);
        // console.log(game);
        // if (game) {
        //     setSelectedGame(game);
        // }
        if (isAuthenticated) {
            playGame();
        }
    }, [isAuthenticated]);
    return _jsx(_Fragment, { children: _jsx("div", __assign({ className: "casino-full-game-bg" }, { children: _jsx("div", __assign({ className: "casino-full-game-container num-casino-games-1" }, { children: _jsxs("div", __assign({ className: "casino-full-game-block", style: { height: "100%", width: "100%" } }, { children: [_jsxs("div", __assign({ className: "casino-full-game-panel" }, { children: [_jsx("i", { onClick: function () {
                                        navigate(base + '/oyunlar');
                                    }, title: "KAPAT", className: "casino-full-game-panel-element bc-i-close-remove" }, void 0), _jsx("i", { title: "UYGUN PENCEREDE A\u00C7", className: "casino-full-game-panel-element bc-i-multiview" }, void 0), _jsx("i", { onClick: function () {
                                        window.location.reload();
                                    }, title: "YEN\u0130LE", className: "casino-full-game-panel-element bc-i-refresh" }, void 0)] }), void 0), _jsx("iframe", { ref: iframeContainerRef, allowFullScreen: true, allow: "autoplay; microphone *" }, void 0)] }), void 0) }), void 0) }), void 0) }, void 0);
}
