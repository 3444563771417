var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Storage } from "../../../../lib/localstorege.service";
import { societywonEventIdMapping } from "../../../societywon/subpages/posts";
import { getEvents } from "../../../../services/societyWon.services";
import { addToBetSlip } from "../../../../services/betslip.services";
import _ from "lodash";
import { betSlipVisibleSubject } from "../../../../store/betslip.store";
import { CouponEventRow } from "../../../societywon/components/event";
import { initialLeftMenu, sportsMenu } from "../../../../lib/misc.functions";
import { GameOperationsApi } from '../../subpages/gameOperations/game.operations.api';
import { modalServiceSubject } from '../../../../components/modal/modal';
import { fillEventStatistics } from "../../../../services/sportsbook.services";
import "../../../societywon/societyWon.css";
import { isMobileView } from "../../../../app";
var couponState = {
    0: 'default',
    1: 'default',
    2: 'success',
    3: 'danger',
};
function SportsDetail(params) {
    var token = Storage.get('token');
    var _a = useState(null), coupon = _a[0], setCoupon = _a[1];
    var _b = useState(false), playable = _b[0], setPlayable = _b[1];
    function closeModal() {
        setCoupon(null);
    }
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        getCouponDetail(params.data);
        // let couponDetailSubject_subscription = couponDetailSubject.subscribe((res: any) => {
        //     console.log(res);
        //     if (res != null) {
        //         getCouponDetail(res);
        //     } else {
        //         closeModal();
        //     }
        //
        // })
        // return () => {
        //     couponDetailSubject_subscription.unsubscribe();
        // }
    }, []);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    var getCouponDetail = function (coupon) {
        var data = {
            page: 0,
            token: token,
            coupon_id: coupon.id,
        };
        if (coupon.id) {
            GameOperationsApi.iddiaDetail(data).then(function (result) {
                if (result.status) {
                    var eventList = result.data.cart_data.map(function (c) {
                        return c.event_id;
                    });
                    var uniqeEventId_1 = _.uniq(eventList);
                    getEvents(uniqeEventId_1).then(function (events) {
                        for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
                            var event_1 = events_1[_i];
                            societywonEventIdMapping[Number(event_1.eventId)] = event_1;
                        }
                        // result.data.cart_data.filter((e:any)=>{
                        //   e.eventObject = events.find((x:any)=>Number(x.eventId)===Number(e.event_id))
                        // })
                        // couponAmount = Number(result.data.amount);
                        // couponQuantity = result.data.coupon_quantity;
                        // couponCreateDate = result.data.create_date;
                        result.data.addtional_ratio = coupon.addtional_ratio;
                        result.data.amount = result.data.unit === 1 ? result.data.amount / 100 : result.data.amount;
                        result.data.profit = (Number(result.data.profit) * Number(coupon.coupon_quantity)) + (Number(result.data.multiply) * Number(coupon.coupon_quantity) * Number(coupon.addtional_ratio));
                        result.data.amount = Number(result.data.amount) * Number(coupon.coupon_quantity);
                        result.data.total_ratio = (Math.round(Number(result.data.total_ratio) * 100) / 100) + Number(result.data.addtional_ratio);
                        result.data.extraProfit = (Number(result.data.multiply) * Number(coupon.coupon_quantity) * Number(coupon.addtional_ratio));
                        fillEventStatistics(uniqeEventId_1).then(function () {
                            setCoupon(result.data);
                            result.data.cart_data.forEach(function (item) {
                                if (item.playableObject) {
                                    setPlayable(true);
                                }
                                else {
                                    setPlayable(false);
                                }
                            });
                        }).catch(function () {
                            setCoupon(result.data);
                        });
                        // betslipAdditionalRatio.customRatios.find((x: any) => {
                        //   var couponDate = couponCreateDate.split("T")[0];
                        //   if (x.beginDate < couponDate && x.endDate > couponDate) {
                        //     additionalRatio = x.addtionalRatio;
                        //     result.data.extraProfit = (additionalRatio * couponAmount * couponQuantity).toFixed(2);
                        //     setCoupon(result.data);
                        //   } else {
                        //     setCoupon(result.data);
                        //     additionalRatio = 0;
                        //   }
                        // })
                    });
                }
                else {
                    modalServiceSubject.next({
                        title: "Hata!",
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                }
            });
        }
    };
    var playSharedCoupon = function (coupon) {
        coupon.cart_data.forEach(function (item) {
            if (item.playableObject && item.playableObject.sbEvent) {
                addToBetSlip(item.playableObject.sbEvent, item.playableObject.sbMarket, item.playableObject.sbOutcome);
            }
        });
    };
    return (coupon ? _jsx(_Fragment, { children: isMobileView ?
            _jsx(_Fragment, { children: coupon.cart_data && coupon.cart_data.map(function (row, rowKey) {
                    // console.log(row.eventObject);
                    var event = societywonEventIdMapping[Number(row.event_id)];
                    // console.log(row)
                    return (_jsx(CouponEventRow, { row: row, event: event, post: coupon }, void 0));
                }) }, void 0)
            : _jsxs("div", __assign({ className: "dataListEl-open" }, { children: [coupon.cart_data && coupon.cart_data.map(function (row, rowKey) {
                        // console.log(row.eventObject);
                        var event = societywonEventIdMapping[Number(row.event_id)];
                        // console.log(row)
                        return (_jsx("div", __assign({ className: "bet-history-t-holder-hk-bc" }, { children: _jsx(CouponEventRow, { row: row, event: event, post: coupon }, void 0) }), void 0));
                    }), _jsx("div", { className: "bet-history-t-holder-hk-bc" }, void 0)] }), void 0) }, void 0)
        : _jsx(_Fragment, {}, void 0));
}
export default SportsDetail;
