var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import "./betslip.css";
import { location, member } from "../../store/login.store";
import { betSlipActionSubject, BetSlipList, BetSlipOption, betSlipVisibleSubject, } from "../../store/betslip.store";
import { BetSlipItem } from "./components/item";
import _ from "lodash";
import { clearBetSlip, updateSystemOptions, setSystemChoice, combinationCalculation, setBetSlipMultiply, setBetSlipConditions, playCoupon, betSlipMaxMultiply, addToBetSlip, sumBetSlipMultiply } from "../../services/betslip.services";
import { useNavigate, useSearchParams } from "react-router-dom";
import { eventDetailSubject, eventMapping, eventUpdateSubject, marketMapping } from "../../store/sportsbook.store";
import { useForceUpdate } from "../../lib/misc.functions";
import { eventDetailVisiblity } from "../../pages/sportsbook/eventdetail";
// import iconBetSlip from "../../assets/img/betslip-icon.png";
import { Location } from '../../models/user.model';
import { isMobileView } from "../../app";
export var betSlipVisiblity = false;
var maxQuantity = 100;
var betslipRecalcTimer = null;
var event = false;
var maxRatioPoint = null;
export default function BetSlipComponent() {
    var _a = useState(false), openConditions = _a[0], setOpenConditions = _a[1];
    var _b = useState(false), openNumpad = _b[0], setOpenNumpad = _b[1];
    var forceUpdate = useForceUpdate();
    //fixme : kullanıcı bazlı kısıtlama gelmeli
    var quantityChoices = _.range(1, 101);
    if (member && member.id) {
        if (member.max_rank && member.max_rank <= 50) {
            quantityChoices.push.apply(quantityChoices, _.range(200, 501, 50));
            maxQuantity = 500;
        }
    }
    var multiplyChoices = _.range(1, 101);
    multiplyChoices.push.apply(multiplyChoices, _.range(200, betSlipMaxMultiply + 1, 50));
    var width = window.innerWidth;
    var _c = useState({ betSlipList: BetSlipList, betSlipOptions: BetSlipOption }), betSlipContext = _c[0], setBetSlipContext = _c[1];
    var _d = useState(width > 969), betSlipVisible = _d[0], setBetSlipVisible = _d[1];
    var betslipAdditionalRatio = 0;
    betSlipContext.betSlipList.forEach(function (i) {
        if (i.outcome.customOdds) {
            var addRatio = Number(i.outcome.fixedOdds) - Number(i.outcome.fixedOddsWeb);
            if (betslipAdditionalRatio < addRatio) {
                betslipAdditionalRatio = Number(addRatio.toFixed(2));
            }
        }
    });
    function onLocation() {
        var object = new Location();
        object.path = "back";
        Object.assign(location, object);
    }
    function bodyVisible() {
        if (betSlipVisible === false) {
            setBetSlipVisible(true);
        }
        else {
            setBetSlipVisible(false);
        }
    }
    if (betSlipVisible && width < 969) {
        document.body.style.overflow = 'hidden';
    }
    else if (eventDetailVisiblity.visible === false) {
        document.body.style.overflow = 'unset';
    }
    useEffect(function () {
        var betSlipVisibleSubject_subject = betSlipVisibleSubject.subscribe(function (state) {
            setBetSlipVisible(state);
            betSlipVisiblity = state;
        });
        return function () {
            betSlipVisibleSubject_subject.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var eventUpdateSubject_subscription = eventUpdateSubject.subscribe(function (events) {
            if (BetSlipOption.inProgress === false) {
                var intersections = betSlipContext.betSlipList.filter(function (el) {
                    if (events.includes(el.sportEvent.eventId)) {
                        return el.sportEvent.eventId;
                    }
                });
                if (intersections.length > 0) {
                    clearTimeout(betslipRecalcTimer);
                    betslipRecalcTimer = setTimeout(function () {
                        updateSystemOptions();
                        combinationCalculation();
                        setBetSlipContext({
                            betSlipList: BetSlipList,
                            betSlipOptions: BetSlipOption,
                        });
                        forceUpdate();
                    }, 300);
                }
            }
        });
        return function () {
            clearTimeout(betslipRecalcTimer);
            eventUpdateSubject_subscription.unsubscribe();
        };
    }, []);
    useEffect(function () {
        // console.log("betSlipActionSubject_subscription>>>>>>>")
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function () {
            updateSystemOptions();
            combinationCalculation();
            setBetSlipContext({
                betSlipList: BetSlipList,
                betSlipOptions: BetSlipOption,
            });
            if (BetSlipOption.bettingInfoStatus === 1) {
                countDown(BetSlipOption.delay);
            }
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var eventDetailSubject_subscription = eventDetailSubject.subscribe(function (res) {
            event = res;
        });
        return function () {
            eventDetailSubject_subscription.unsubscribe();
            event = null;
        };
    }, []);
    // useEffect(() => {
    //     let cachedPoint = Storage.get('cached_available_bonus');
    //     if (cachedPoint) {
    //         maxRatioPoint = Number(cachedPoint);
    //         if (maxRatioPoint !== Number(cachedPoint)) {
    //             forceUpdate();
    //         }
    //     } else {
    //         const api = new ApiService();
    //         api.start('post', env.core_api + '/marketing/api/available-bonus/', Storage.get('token') ? {token: Storage.get('token')} : null, true, 3000)
    //             .then((res: any) => {
    //                 Storage.set('cached_available_bonus', res.data.available_bonus, 60 * 1000);
    //                 maxRatioPoint = Number(res.data.available_bonus);
    //                 forceUpdate();
    //             });
    //     }
    // }, [betSlipContext.betSlipOptions.betSlipMultiply, betSlipContext.betSlipOptions.betSlipQuantity]);
    var countDown = function (s) {
        var divCountDown = document.getElementById('divCountDown');
        if (divCountDown) {
            divCountDown.innerText = 'Onay bekliyor...';
            // divCountDown.innerText = String(s) + ' saniye...';
            if (s > 0) {
                setTimeout(function () {
                    countDown(s -= 1);
                }, 1300);
            }
        }
    };
    if (betSlipVisible && betSlipContext && betSlipContext.hasOwnProperty('betSlipList')) {
        var couponColumn = betSlipContext.betSlipOptions.systemCombinations.length;
        var couponCondition = betSlipContext.betSlipOptions.couponCondition;
        var couponTotalRatio = betSlipContext.betSlipOptions.totalRatio;
        var betSlipMultiply = betSlipContext.betSlipOptions.betSlipMultiply;
        var betSlipQuantity = betSlipContext.betSlipOptions.betSlipQuantity;
        var couponAmount = couponColumn * betSlipMultiply * betSlipQuantity;
        var diffProfit = betslipAdditionalRatio * betSlipMultiply * betSlipQuantity;
        if (maxRatioPoint !== null && maxRatioPoint === 0) {
            betslipAdditionalRatio = 0;
        }
        var maxProfit = (couponTotalRatio + betslipAdditionalRatio) * betSlipMultiply * betSlipQuantity;
        if (maxRatioPoint !== null && diffProfit > maxRatioPoint) {
            diffProfit = maxRatioPoint;
            maxProfit = (couponTotalRatio * betSlipMultiply * betSlipQuantity) + diffProfit;
        }
        /*
            if (betSlipContext.betSlipOptions.bettingInfoMessage !== null) {
              return (<div className="betslip-wrapper">
                <div className="header">
                  {!betSlipContext.betSlipOptions.bettingInfoStatus || betSlipContext.betSlipOptions.bettingInfoStatus === 0 ? 'Kupon oynanıyor...' : ''}
                  {betSlipContext.betSlipOptions.bettingInfoStatus === 1 ? 'Kupon oynanıyor...' : ''}
                  {betSlipContext.betSlipOptions.bettingInfoStatus === 2 ? 'MBS onaylamadı' : ''}
                  {betSlipContext.betSlipOptions.bettingInfoStatus === 3 ? 'Kupon oynandı' : ''}
                  {betSlipContext.betSlipOptions.bettingInfoStatus === 1 ? <span>Bekleyiniz</span> : <></>}
                </div>
                <div className="text-center mt-5 p-2">
                  {betSlipContext.betSlipOptions.bettingInfoStatus === 1 ? <p className="text-center"><img className="rotationImage" src={Clock} width={50} /></p> : ''}
                  {betSlipContext.betSlipOptions.bettingInfoStatus === 2 ? <p className="text-center"><img src={AlertCircle} width={50} /></p> : ''}
                  {betSlipContext.betSlipOptions.bettingInfoStatus === 3 ? <p className="text-center"><img src={CheckCircle} width={50} /></p> : ''}
                  <p className="text-center" dangerouslySetInnerHTML={{ __html: betSlipContext.betSlipOptions.bettingInfoMessage }}></p>
                  {betSlipContext.betSlipOptions.bettingInfoStatus === 1 ? <div id="divCountDown" className="text-center fw-bold"></div> : ''}
                </div>
              </div>)
            }

            if (betSlipContext.betSlipList.length === 0) {
              return (<div className="betslip-wrapper">
                <div className="header" onClick={() => bodyVisible()}>
                  Kuponum <i className="badge">{betSlipContext.betSlipList.length}</i>
                  <span>
                    {/!*Oran: {couponTotalRatio.toFixed(2)}*!/}
                  </span>
                </div>
                <div className="text-center mt-5">
                  <p>Kuponunuzda maç bulunmamaktadır.<br />
                    Maç eklemek için oranların üzerine tıklayabilirsiniz
                  </p>
                </div>
              </div>)
            }*/
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "layout-betslip-holder-bc" }, { children: _jsx("div", __assign({ className: "betslip-bc ".concat(isMobileView ? 'Full' : window.location.href.includes("sports") ? 'Fixed' : 'Full', "  full"), style: { transform: "translateY(0px)", opacity: "1" } }, { children: _jsxs("div", __assign({ id: "betslip_container", className: "", onClick: function (event) {
                            if (openNumpad && (!event.target.classList.contains('bs-number-button-bc') && !event.target.classList.contains('bs-number-button-icon-bc'))) {
                                setOpenNumpad(false);
                            }
                        } }, { children: [!window.location.href.includes("sports") && _jsx("div", __assign({ className: "" }, { children: _jsx("button", { onClick: function () {
                                        betSlipVisibleSubject.next(!betSlipVisiblity);
                                        var root = document.getElementById('root');
                                        root.classList.remove("betslip-Full");
                                        root.classList.add("betslip-Hidden");
                                    }, className: "e-p-close-icon-bc bc-i-close-remove ", type: "button" }, void 0) }), void 0), !isMobileView && _jsx("div", __assign({ className: "" }, { children: _jsx("div", { className: "banner-a-lot" }, void 0) }), void 0), _jsxs("div", __assign({ className: "betslip-full-content-bc" }, { children: [_jsxs("div", __assign({ className: "bs-f-header-bc" }, { children: [isMobileView && _jsx("div", __assign({ className: "bs-f-header-top-stage-bc", onClick: function () {
                                                    betSlipVisibleSubject.next(!betSlipVisiblity);
                                                } }, { children: _jsx("i", { className: "bs-f-h-t-close-icon-bc bc-i-close-remove" }, void 0) }), void 0), _jsx("div", __assign({ className: "second-tabs-bc" }, { children: _jsx("div", __assign({ className: "tab-bc selected-underline betslip active", title: "BAH\u0130S KUPONU" }, { children: _jsx("span", { children: "BAH\u0130S KUPONU" }, void 0) }), void 0) }), void 0)] }), void 0), betSlipContext.betSlipList.length > 0 &&
                                        _jsxs("div", __assign({ className: "bs-f-header-sub-navigation-bc " }, { children: [_jsx("div", __assign({ className: "form-control-bc select s-small filled" }, { children: _jsx("div", __assign({ className: "form-control-label-bc form-control-select-bc inputs " }, { children: betSlipContext.betSlipOptions.systemOptions.filter(function (el) { return el.sysSelected; }).length > 0 ?
                                                            _jsx("span", __assign({ className: "ellipsis" }, { children: "Sistem" }), void 0) :
                                                            _jsx("span", __assign({ className: "ellipsis" }, { children: "Kombine" }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "bs-remove-all-bc" }, { children: _jsx("span", __assign({ onClick: function () {
                                                            clearBetSlip();
                                                        }, className: "betslip-remove-all ellipsis" }, { children: "T\u00FCm\u00FCn\u00FC Kald\u0131r" }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "bs-f-body-bc" }, { children: _jsxs("div", __assign({ className: "bs-scroll-container-bc", "data-scroll-lock-scrollable": "" }, { children: [betSlipContext.betSlipList.length === 0 &&
                                                    _jsx("p", __assign({ className: "empty-b-text-v-bc" }, { children: "Bahis kuponunuz bo\u015F" }), void 0), betSlipContext.betSlipList.length > 0 &&
                                                    _jsx("div", __assign({ className: "bs-f-b-content-bc betslip" }, { children: betSlipContext.betSlipList.map(function (item, key) {
                                                            return _jsx(BetSlipItem, { item: item }, key);
                                                        }) }), void 0), betSlipContext.betSlipList.length > 0 && _jsx("div", __assign({ className: "bs-actions-bc" }, { children: _jsxs("div", __assign({ className: "bs-inputs-bc" }, { children: [betSlipContext.betSlipOptions.systemOptions && betSlipContext.betSlipOptions.systemOptions.length > 0 &&
                                                                _jsx("div", __assign({ className: "bs-bet-i-b-section-bc" }, { children: _jsxs("div", __assign({ className: "bs-bet-i-b-row-bc" }, { children: [_jsx("div", __assign({ className: "bs-bet-i-b-cell-bc title" }, { children: _jsx("span", __assign({ className: "bs-bet-i-b-title-bc t-2 ellipsis" }, { children: "Sistem" }), void 0) }), void 0), _jsx("div", __assign({ className: "bs-bet-i-b-cell-bc value" }, { children: _jsxs("span", __assign({ className: "bs-bet-i-b-coefficient-bc c-2" }, { children: ["Kolon: ", betSlipContext.betSlipOptions.systemCombinations.length] }), void 0) }), void 0)] }), void 0) }), void 0), betSlipContext.betSlipOptions.systemOptions && betSlipContext.betSlipOptions.systemOptions.length > 0 &&
                                                                _jsx("div", __assign({ className: "bs-bet-i-b-section-bc" }, { children: _jsx("div", __assign({ className: "bs-bet-i-b-row-bc" }, { children: _jsx("div", __assign({ className: "bs-bet-i-b-cell-bc title" }, { children: betSlipContext.betSlipOptions.systemOptions.map(function (item, key) {
                                                                                return (_jsx("button", __assign({ onClick: function () {
                                                                                        setSystemChoice(item);
                                                                                    }, className: " bs-bet-i-b-title-bc t-2 ellipsis market-bc  systemChoices " + (item.sysSelected ? 'active' : '') }, { children: item.sysNum }), key));
                                                                            }) }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "bs-bet-i-b-section-bc" }, { children: _jsxs("div", __assign({ className: "bs-bet-i-b-row-bc" }, { children: [_jsx("div", __assign({ className: "bs-bet-i-b-cell-bc title" }, { children: _jsx("span", __assign({ className: "bs-bet-i-b-title-bc t-2 ellipsis" }, { children: "Oran" }), void 0) }), void 0), _jsx("div", __assign({ className: "bs-bet-i-b-cell-bc value" }, { children: _jsx("span", __assign({ className: "bs-bet-i-b-coefficient-bc c-2" }, { children: couponTotalRatio.toFixed(2) }), void 0) }), void 0)] }), void 0) }), void 0), _jsxs("ul", __assign({ className: "bs-bet-i-b-stake-bc" }, { children: [isMobileView && _jsx("li", __assign({ "data-numpad-open": "", className: "bs-bet-i-b-s-i-block-bc bs-bet-i-b-s-i-holder-bc", onClick: function () {
                                                                            setOpenNumpad(true);
                                                                            setTimeout(function () {
                                                                                try {
                                                                                    var scrollContainer = document.getElementsByClassName('bs-scroll-container-bc')[0];
                                                                                    scrollContainer.scrollTo(0, scrollContainer.scrollHeight);
                                                                                }
                                                                                catch (e) {
                                                                                    console.log(e);
                                                                                }
                                                                            }, 500);
                                                                        } }, { children: _jsx("span", __assign({ "data-numpad-open": "", className: "ellipsis quickBetInput-text-bc" }, { children: BetSlipOption.betSlipMultiply.toString() == '0' ? 'Bahis Miktarı Girin' : BetSlipOption.betSlipMultiply }), void 0) }), void 0), !isMobileView && _jsx("li", __assign({ "data-numpad-open": "", className: "bs-bet-i-b-s-i-block-bc bs-bet-i-b-s-i-holder-bc" }, { children: _jsx("input", { className: "bs-bet-i-b-s-i-bc simple", type: "text", pattern: "^[0-9\\b]+$", min: 1, max: betSlipMaxMultiply, step: 1, value: BetSlipOption.betSlipMultiply || '', onChange: function (e) { return setBetSlipMultiply(Number(e.target.value)); }, onPaste: function (e) {
                                                                                e.preventDefault();
                                                                                return false;
                                                                            }, placeholder: "Bahis Miktar\u0131 Girin" }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "bs-bet-i-b-section-bc" }, { children: _jsxs("div", __assign({ className: "bs-bet-i-b-row-bc" }, { children: [_jsx("div", __assign({ className: "bs-bet-i-b-cell-bc title" }, { children: _jsx("span", __assign({ className: "bs-bet-i-b-title-bc t-2 ellipsis" }, { children: "Olas\u0131 kazan\u00E7:" }), void 0) }), void 0), _jsx("div", __assign({ className: "bs-bet-i-b-cell-bc value" }, { children: _jsxs("span", __assign({ className: "bs-bet-i-b-amount-bc" }, { children: [maxProfit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(",", "!").replace(".", ",").replace("!", "."), "\u20BA"] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ className: "bs-f-footer " }, { children: [_jsx("div", { className: "bet-slip-switcher-wrapper-bc" }, void 0), BetSlipOption.errorMessage &&
                                                _jsx("div", __assign({ className: "bs-info-massages" }, { children: _jsxs("div", __assign({ className: "quickBetMsgWrapper-bc betslip-warning-message " }, { children: [_jsx("i", { className: "bs-notification-icon-bc bc-i-attention" }, void 0), _jsxs("span", __assign({ className: "quickBetMsgText-bc" }, { children: [BetSlipOption.errorMessage, " "] }), void 0)] }), void 0) }), void 0), !isMobileView && _jsx("div", __assign({ className: "bs-numbers-bc " }, { children: _jsxs("div", __assign({ className: "bs-editable-number-buttons-bc" }, { children: [_jsx("span", __assign({ onClick: function () {
                                                                sumBetSlipMultiply(1);
                                                            }, className: "bs-number-button-bc i-2 ellipsis" }, { children: "1" }), void 0), _jsx("span", __assign({ onClick: function () {
                                                                sumBetSlipMultiply(5);
                                                            }, className: "bs-number-button-bc i-2 ellipsis" }, { children: "5" }), void 0), _jsx("span", __assign({ onClick: function () {
                                                                sumBetSlipMultiply(10);
                                                            }, className: "bs-number-button-bc i-2 ellipsis" }, { children: "10" }), void 0), _jsx("span", __assign({ onClick: function () {
                                                                sumBetSlipMultiply(20);
                                                            }, className: "bs-number-button-bc i-2 ellipsis" }, { children: "20" }), void 0), _jsx("span", __assign({ onClick: function () {
                                                                sumBetSlipMultiply(50);
                                                            }, className: "bs-number-button-bc i-2 ellipsis" }, { children: "50" }), void 0), _jsx("span", __assign({ onClick: function () {
                                                                setBetSlipMultiply(0);
                                                            }, className: "bs-number-button-bc i-2 ", title: "Cancel" }, { children: "C" }), void 0)] }), void 0) }), void 0), isMobileView && openNumpad && _jsxs("div", __assign({ className: "bs-numbers-bc " }, { children: [_jsxs("div", __assign({ className: "bs-number-buttons-bc" }, { children: [_jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "1", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "1" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "2", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "2" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "3", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "3" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "4", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "4" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "5", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "5" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "6", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "6" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "7", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "7" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "8", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "8" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "9", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "9" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-2", "data-value": "C", onClick: function (e) {
                                                                    setBetSlipMultiply(0);
                                                                } }, { children: "C" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-1", "data-value": "0", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString() + e.target.getAttribute('data-value')));
                                                                } }, { children: "0" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-2", onClick: function (e) {
                                                                    setBetSlipMultiply(Number(BetSlipOption.betSlipMultiply.toString().substring(0, BetSlipOption.betSlipMultiply.toString().length - 1)));
                                                                } }, { children: _jsx("i", { className: "bs-number-button-icon-bc bc-i-delete" }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "bs-editable-number-buttons-bc" }, { children: [_jsx("span", __assign({ className: "bs-number-button-bc i-2 ellipsis", onClick: function () {
                                                                    sumBetSlipMultiply(5);
                                                                } }, { children: "5" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-2 ellipsis", onClick: function () {
                                                                    sumBetSlipMultiply(10);
                                                                } }, { children: "10" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-2 ellipsis", onClick: function () {
                                                                    sumBetSlipMultiply(20);
                                                                } }, { children: "20" }), void 0), _jsx("span", __assign({ className: "bs-number-button-bc i-2 ellipsis", onClick: function () {
                                                                    sumBetSlipMultiply(50);
                                                                } }, { children: "50" }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "settings-r-b-row-bc", style: {
                                                    margin: "0 10px",
                                                    borderRadius: '4px',
                                                    border: '1px solid rgba(var(--oc-1), 1)'
                                                } }, { children: _jsxs("div", __assign({ className: "form-control-bc select s-small has-icon filled", tabIndex: 0 }, { children: [_jsxs("div", __assign({ className: "form-control-label-bc form-control-select-bc inputs ", onClick: function () {
                                                                setOpenConditions(function (prevState) { return !prevState; });
                                                            } }, { children: [_jsx("i", { className: "select-icon-bc bc-i-settings" }, void 0), _jsx("span", __assign({ className: "ellipsis" }, { children: couponCondition == "1" ? "Tüm oran değişimlerini kabul et" : couponCondition == "2" ? "Sadece yükselen oranları kabul et" : "Oran değişikliklerini kabul etme" }), void 0), _jsx("i", { className: "form-control-icon-bc bc-i-small-arrow-".concat(openConditions ? 'up' : 'down') }, void 0), _jsx("i", { className: "form-control-input-stroke-bc" }, void 0)] }), void 0), openConditions &&
                                                            _jsxs("div", __assign({ className: "multi-select-label-bc", "data-scroll-lock-scrollable": "" }, { children: [_jsx("label", __assign({ onClick: function () {
                                                                            setBetSlipConditions("1");
                                                                            setOpenConditions(function (prevState) { return !prevState; });
                                                                        }, className: "checkbox-control-content-bc ".concat(couponCondition == '1' ? 'active' : '', " ") }, { children: _jsx("p", __assign({ className: "checkbox-control-text-bc ellipsis", style: { pointerEvents: "none" } }, { children: "T\u00FCm oran de\u011Fi\u015Fimlerini kabul et" }), void 0) }), void 0), _jsx("label", __assign({ onClick: function () {
                                                                            setBetSlipConditions("2");
                                                                            setOpenConditions(function (prevState) { return !prevState; });
                                                                        }, className: "checkbox-control-content-bc ".concat(couponCondition == '2' ? 'active' : '', " ") }, { children: _jsx("p", __assign({ className: "checkbox-control-text-bc ellipsis", style: { pointerEvents: "none" } }, { children: "Sadece y\u00FCkselen oranlar\u0131 kabul et" }), void 0) }), void 0), _jsx("label", __assign({ onClick: function () {
                                                                            setBetSlipConditions("3");
                                                                            setOpenConditions(function (prevState) { return !prevState; });
                                                                        }, className: "checkbox-control-content-bc ".concat(couponCondition == '3' ? 'active' : '', " ") }, { children: _jsx("p", __assign({ className: "checkbox-control-text-bc ellipsis", style: { pointerEvents: "none" } }, { children: "Oran de\u011Fi\u015Fikliklerini kabul etme" }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0), isMobileView &&
                                                _jsx("div", __assign({ className: "bet-button-wrapper-bc content-type-0", style: { border: "0" } }, { children: _jsx("button", __assign({ onClick: function () {
                                                            betSlipVisibleSubject.next(!betSlipVisiblity);
                                                            var root = document.getElementById('root');
                                                            root.classList.remove("betslip-Full");
                                                            root.classList.add("betslip-Hidden");
                                                        }, className: "btn button-type-0 ", type: "button", title: "Bahis Yap" }, { children: _jsx("span", { children: "B\u00FCltene D\u00F6n" }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "bet-button-wrapper-bc content-type-0" }, { children: _jsx("button", __assign({ onClick: function () { return playCoupon(); }, className: "btn a-color button-type-0 ", type: "button", title: "Bahis Yap" }, { children: _jsx("span", { children: "Bahis Yap" }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0) }, void 0));
    }
    else {
        if (BetSlipList.length > 0) {
            return (_jsx(_Fragment, {}, void 0));
        }
        else {
            return _jsx(_Fragment, {}, void 0);
        }
    }
}
export function AddToCartService() {
    var navigate = useNavigate();
    var params = useSearchParams()[0];
    console.log(params.get('e'));
    useEffect(function () {
        var _a;
        if (params.get('e') !== null) {
            var oddInfo_1 = (_a = params.get('e')) === null || _a === void 0 ? void 0 : _a.split('-');
            var eventObj = eventMapping[String(oddInfo_1[0])];
            var marketObj = void 0;
            var outcomeObj = void 0;
            if (eventObj) {
                marketObj = marketMapping[String(oddInfo_1[0])].find(function (m) { return Number(m.marketId) === Number(oddInfo_1[1]); });
                if (marketObj) {
                    outcomeObj = marketObj.marketOutcome.find(function (el) { return Number(el.outcomeNo) === Number(oddInfo_1[2]); });
                }
            }
            if (eventObj && marketObj && outcomeObj) {
                addToBetSlip(eventObj, marketObj, outcomeObj, 'add');
                betSlipVisibleSubject.next(true);
            }
            if (eventObj) {
                navigate("/sports/".concat(eventObj.sportSlug, "/tumu/").concat(eventObj.competitionId, "/").concat(eventObj.eventId, "/"));
            }
            else {
                navigate("/sports/futbol/tumu/");
            }
        }
        else {
            navigate('/');
        }
    }, [params.get('e')]);
    return (_jsx(_Fragment, {}, void 0));
}
